import * as React from "react"
import Layout from "../components/layout"
import IndexPage from "../components/pageComponents/index"
import messages from "../i18n/hr"

const IndexPageHr = ({ location }) => (
  <Layout location={location} messages={messages}>
    <IndexPage langKey="hr" />
  </Layout>
)

export default IndexPageHr
